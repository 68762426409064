import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection/Header';
import InfoSection from '../components/InfoSection';
import InfoSection2 from '../components/InfoSection/index2';
import InfoSection1 from '../components/InfoSection/index1';
import InfoSection3 from '../components/InfoSection/index3';
import Services from '../components/Services';
import Footer from '../components/Footer';

const Home = () => {
   

    return (
        <>
            <HeroSection />
            <InfoSection />
            <InfoSection1 />
            <InfoSection2 />
            <InfoSection3 />
            <Services />
            <Footer />
        </>
    );
};

export default Home;