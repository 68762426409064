import React from "react";
import "./Button.css"

const Button = () => {
  return (
<div className="mainbtn">
<div>
<a href="https://play.google.com/store/apps/details?id=com.qci.aimhundredapp&pli=1" target="_blank" tabIndex="0">
  <img className="bn45" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png" alt="bn45"/>
  </a>
</div>
<div>
  </div>

</div>




  );
};

export default Button;
