import React from 'react';
import ScrollToTop from '../components/ScrollToTop';

const SigninPage = () => {
    return (
        <>
        <ScrollToTop />
        </>
    );
};

export default SigninPage