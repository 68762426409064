import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
} from "./InfoElements";
import img1 from "./img/img6.png";

import Fade from "react-reveal/Fade";
import Wobble from "react-reveal/Wobble";

const InfoSection = () => {
  return (
    <>
      <InfoContainer lightBg={true} id="about">
        <InfoWrapper>
          <InfoRow imgStart={true}>
            <Column1>
              <TextWrapper>
                <Wobble>
                  <TopLine>About Us</TopLine>
                </Wobble>

                <Fade right>
                  <Heading lightText={false}>
                    Being dedicated to learners
                  </Heading>
                  <Subtitle darkText={true}>
                    Empowering learners by democratizing Education,
                    Employability and Employment{" "}
                  </Subtitle>
                  <Subtitle darkText={true}>
                    Actively listening to & having empathy for learners to
                    create solutions that enrich them
                  </Subtitle>
                </Fade>
              </TextWrapper>
            </Column1>
            <Column2>
              <Fade left>
                <ImgWrap>
                  <Img src={img1} alt="rock" />
                </ImgWrap>
              </Fade>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
