import React from "react";
import "./style/Privacypolicy.css";
const Privacypolicy = () => {
  return (
    <div className="privacy-policy">
      <img className="img" src="/logo.png" alt="Logo"></img>
      <h2>Privacy Policy for AIM HUNDRED </h2>
      <h1>Introduction</h1>
      <p>
        Welcome to Aim Hundred Website. At Aim hundred Educational Website, we
        are committed to protecting your privacy and ensuring the security of
        your personal information. This Privacy Policy explains how we collect,
        use, disclose, and protect your personal information when you use our
        website and its services.
      </p>

      <h2>Information We Collect</h2>
      <h3>2.1. Information You Provide</h3>
      <p>
        We may collect personal information that you voluntarily provide when
        you register, subscribe, complete online forms, or otherwise interact
        with our website. This information may include, but is not limited to:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Date of birth</li>
        <li>Educational background</li>
        <li>Contact information</li>
        <li>User-generated content (e.g., comments, forum posts)</li>
      </ul>

      <h3>2.2. Automatically Collected Information</h3>
      <p>
        We may also collect certain information automatically when you visit our
        website, such as:
      </p>
      <ul>
        <li>IP address</li>
        <li>Browser type and version</li>
        <li>Operating system</li>
        <li>Referring URL</li>
        <li>Usage data (e.g., pages visited, time spent on the website)</li>
      </ul>

      {/* ... More sections ... */}

      <h2>Contact Us</h2>
      <p>
        If you have any questions, concerns, or requests related to this Privacy
        Policy, please contact us at <a href="tel:8428421300">8428421300</a>.
      </p>
    </div>
  );
};

export default Privacypolicy;
