import React from "react";
import "./style/About.css";
const Aboutus = () => {
  return (
    <div className="App">
      <img className="img" src="/logo.png" alt="Logo"></img>
      <h1 className="head">
        <u>About US</u>
      </h1>
      <h3 className="">
        {" "}
        Welcome to Aim Hundred Website – Your Destination for Quality Education!
      </h3>
      <p className="content">
        Aim Hundred website is mainly designed and developed to make exams easy
        through one word Q&A. Students can practice and participate on daily one
        mark exams and gain the subject oriented knowledge. Aim Hundred will
        help the students to learn and practice their subjects easily. Also it
        helps students to score more marks in exams
      </p>
      <h3 className="topic">Our Mission</h3>
      <p className="content">
        {" "}
        At [Aim hundred Website], our mission is to provide accessible,
        high-quality educational content that empowers individuals to learn,
        grow, and succeed in their personal and professional lives. We believe
        that knowledge is the key to unlocking one's full potential.
      </p>
      <h3 className="topic">Interactive Learning</h3>
      <p className="content">
        We believe in the power of interactive and engaging learning
        experiences. Our courses feature multimedia content, quizzes,
        assignments, and discussion forums to facilitate active learning and
        knowledge retention.
      </p>
      <h3 className="topic">Contact Us</h3>
      <p className="content">
        If you have any questions, suggestions, or feedback, please don't
        hesitate to reach out to us at [8428421300]. We value your input and are
        always striving to enhance your learning experience.
      </p>
      <h3 className="footer">
        Thank you for choosing Aim Hundred as your partner in education!
      </h3>
    </div>
  );
};

export default Aboutus;
