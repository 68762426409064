import React from "react";
import "./style/shippingpolicy.css";

const shippingpolicy = () => {
  return (
    <div className="shipping-policy-container">
      <img className="img" src="/logo.png" alt="Logo"></img>
      <h1>Shipping Policy for AIM HUNDRED </h1>
      <p>
        By placing an order on Aim Hundred Website, you agree to comply with
        and be bound by this Shipping Policy. Please read this policy carefully
        before making any purchases. If you do not agree to this policy, please
        do not place an order.
      </p>

      <h2>1. Shipping Destinations</h2>
      <p>
        1.1. We currently offer shipping to customers within the United States
        and internationally, unless otherwise stated on the product page.
        Shipping fees and delivery times may vary depending on the destination.
      </p>

      <h2>2. Shipping Methods</h2>
      <p>
        2.1. We use reputable shipping carriers to deliver your orders. The
        available shipping methods may include, but are not limited to, standard
        shipping, express shipping, and international shipping.
      </p>
      <p>
        2.2. The shipping method for your order will be specified during the
        checkout process. You will also receive a shipping confirmation email
        with tracking information once your order has been shipped.
      </p>
      <h2>3. Shipping Costs</h2>
      <p>
        3.1. Shipping costs are calculated based on the shipping method
        selected, the destination of the order, and the weight or dimensions of
        the items being shipped. Shipping fees will be displayed during the
        checkout process for your review before you complete the purchase.
      </p>
      <h2>4. Delivery Times</h2>
      <p>
        4.1. Estimated delivery times vary depending on the shipping method and
        the destination. We strive to process and ship orders as quickly as
        possible. However, delivery times may be subject to delays beyond our
        control, such as customs clearance for international shipments.
      </p>
      <p>
        4.2. You will receive an estimated delivery date during the checkout
        process. Please note that this is an estimate, and actual delivery times
        may vary.
      </p>
      <h2>5. Order Tracking</h2>
      <p>
        5.1. You can track the status of your order using the tracking
        information provided in the shipping confirmation email. If you have any
        questions about the status of your order, please contact our customer
        support team at [info@aimhundred.com].
      </p>
      <h2>6. Shipping Restrictions</h2>
      <p>
        6.1. We may not be able to ship certain products to specific locations
        due to legal restrictions or other limitations. Any such restrictions
        will be indicated on the product page.
      </p>
      <h2>7. Shipping Charges and Taxes</h2>
      <p>
        7.1. Shipping charges are non-refundable, except in cases where we have
        made an error in processing or shipping your order.
      </p>
      <p>
        7.2. Customers are responsible for any customs duties, taxes, or import
        fees that may apply to international shipments. These fees are not
        included in the purchase price or shipping fees.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns regarding this Shipping Policy or
        your order, please contact us at [8428421300].
      </p>
    </div>
  );
};

export default shippingpolicy;
