import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
} from "./InfoElements";
import img4 from './img/img8.png'
import Slide from 'react-reveal/Slide';

const InfoSection = () => {
  return (
    <>
      <InfoContainer lightBg={false} >
        <InfoWrapper>
          <InfoRow imgStart={true}>
            <Column1>
            <Slide bottom>
              <TextWrapper>
                <TopLine>Unlimited Exposure</TopLine>
                <Heading lightText={true}>Pratice & Exam</Heading>
                <Subtitle darkText={false}>Students get to attend MasterClass with renowned personalities and participate in national-level competitions that ensure your child undergoes holistic development.</Subtitle>
              </TextWrapper>
            </Slide>
            </Column1>
            <Column2>
            <Slide top>
              <ImgWrap>
                <Img src= {img4} alt="mobile" />
              </ImgWrap>
              </Slide>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
