import React from 'react';
import './Card.css'
import Zoom from 'react-reveal/Zoom';
import img1 from './image/img.gif';
import img2 from './image/img1.gif';
import img3 from './image/img2.gif';
import img4 from './image/img3.gif';

const Card = () => {
  return(
<div className="cards-list">
  
<Zoom>
  <div className="card 1">
    <div className="card_image"> <img src={img3} /> </div>
    <div className="card_title title-white">
      <p>Learning</p>
    </div>
  </div>
  
    <div className="card 2">
    <div className="card_image">
      <img src={img2} />
      </div>
    <div className="card_title title-white">
      <p>Intelligence</p>
    </div>
  </div>
  
  <div className="card 3">
    <div className="card_image">
      <img src={img1} />
    </div>
    <div className="card_title">
      <p>Focus</p>
    </div>
  </div>
    
    <div className="card 4">
    <div className="card_image">
      <img src={img4} />
      </div>
    <div className="card_title title-black">
      <p>Study</p>
    </div>
    </div>
    </Zoom>
  </div>

  );
};

export default Card;
