import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
} from "./InfoElements";
import img2 from './img/img7.png'
import Slide from 'react-reveal/Slide';


const InfoSection = () => {
  return (
    <>
      <InfoContainer lightBg={false} id="features">
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
            <Slide left>
            <TextWrapper>
                <TopLine>Features</TopLine>
                <Heading lightText={true}>Exam & pratice.</Heading>
                <Subtitle darkText={false}>The AimHundred ensures learning never stops and parents become an involved part of their child’s learning.</Subtitle>
                <Subtitle darkText={false}>Students get to attend MasterClass with renowned personalities and participate in national-level competitions that ensure your child undergoes holistic development.</Subtitle>
                <Subtitle darkText={false}>Your child gets to network & learn with students across the country</Subtitle>
              </TextWrapper>
            </Slide>
            </Column1>
            <Column2>
            <Slide right>
              <ImgWrap>
                <Img src= {img2} alt="rock" />
              </ImgWrap>
              </Slide>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
