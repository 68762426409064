import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import SigninPage from "./pages/signin";
import Aboutus from "./pages/Aboutus";
import Teamandcondition from "./pages/Teamandcondition";
import Privacypolicy from "./pages/Privacypolicy";
import shippingpolicy from "./pages/shippingpolicy";
import Refundandcancellation from "./pages/Refundandcancellation";
import Pricing from "./pages/Pricing";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/signin" component={SigninPage} exact />
        <Route path="/aboutus" component={Aboutus} exact />
        <Route path="/teamandcondition" component={Teamandcondition} exact />
        <Route path="/privacypolicy" component={Privacypolicy} exact />
        <Route path="/shippingpolicy" component={shippingpolicy} exact />
        <Route
          path="/refundandcancellation"
          component={Refundandcancellation}
          exact
        />
        <Route path="/pricing" component={Pricing} exact />
      </Switch>
    </Router>
  );
}

export default App;
