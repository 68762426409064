import styled from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: start;
  align-items: end;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  @media screen and (max-width: 960px) {
    transition: 0.8s all-ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: stretch;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 100%;
`;

export const LogButton = styled.button`
  background-image: linear-gradient(rgb(13, 204, 234), rgb(13, 112, 234));
  border: 0px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 0.9em;
  margin: 5px;
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  touch-action: manipulation;
`;

export const NavLogo = styled(LinkRouter)`
  // color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  background: linear-gradient(to right, hsl(98 100% 62%), hsl(204 100% 59%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const MobileIcon = styled.div`
  display: none;

  // mobile only display
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  margin-left: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkScroll)`
  color: #fff;
  display: flex;

  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  //bottom green bar when selected
  &.active {
    border-bottom: 3px solid #01bf71;
  }
`;
export const NavLink = styled.a`
  color: #fff;
  display: flex;

  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  //bottom green bar when selected
  &.active {
    border-bottom: 3px solid #01bf71;
  }
`;
