import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FLink,
} from "./FooterElements";

const Footer = () => {
  //function that scrolls to top of page
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer id="contact">
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>AimHundred</FooterLinkTitle>
              <FooterLink to="/">13/2 ,Kattursadayappan street</FooterLink>
              <FooterLink to="/">Periyamet</FooterLink>
              <FooterLink to="/">Chennai 600003, Tamil Nadu</FooterLink>
              <FooterLink to="/">8428421300</FooterLink>
              <FooterLink to="/">info@AimHundred.com</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              {/* 
              
              
              */}
              <FooterLinkTitle>Useful Links</FooterLinkTitle>
              <FLink
                to="/"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Home
              </FLink>
              <FLink
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                About us
              </FLink>
              <FLink
                to="features"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                features
              </FLink>
              <FLink
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Services
              </FLink>
              <FooterLink to="/">Contact us</FooterLink>
              <FooterLink to="/pricing">Pricing</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Our Services</FooterLinkTitle>
              <FooterLink to="/aboutus">About Services</FooterLink>
              <FooterLink to="/shippingpolicy">Shipping policy</FooterLink>
              <FooterLink to="/privacypolicy">Privacy policy</FooterLink>
              <FooterLink to="/teamandcondition">
                Teams and Condition
              </FooterLink>
              <FooterLink to="/refundandcancellation">
                Refund and Cancellation policy
              </FooterLink>
            </FooterLinkItems>
            {/* <FooterLinkItems>
              <FooterLinkTitle>Our Services</FooterLinkTitle>
              <FooterLink to="/">Provides Stability</FooterLink>
              <FooterLink to="/">Needed For Equality</FooterLink>
              <FooterLink to="/">A Safer World</FooterLink>

              <FooterLink to="/">Confidence</FooterLink>
            </FooterLinkItems> */}
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              AimHundred
            </SocialLogo>
            <WebsiteRights>
              AimHundred © {new Date().getFullYear()} All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="//www.twitter.com/"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="YouTube">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
