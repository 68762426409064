import React from "react";
import "./style/Refundandcancellation.css";
const Refundandcancellation = () => {
  return (
    <div className="policy-container">
      <img className="img" src="/logo.png" alt="Logo"></img>
      <h2>Refund and Cancellation Policy</h2>
      <p>
        By using Aim Hundred Website, you agree to comply with and be bound by
        this Refund and Cancellation Policy. Please read this policy carefully
        before making any purchases on our website. If you do not agree to this
        policy, please do not use our services.
      </p>
      <div className="policy-section">
        <h3>1. Refund Policy</h3>
        <div className="policy-subsection">
          <h4>1.1 Refund Eligibility:</h4>
          <p>We offer refunds under the following circumstances:</p>
          <ul>
            <li>
              Course Cancellation: If we cancel a course or educational program
              that you have paid for, you will be eligible for a full refund.
            </li>
            <li>
              Dissatisfaction: If you are dissatisfied with a paid course or
              educational program and request a refund within [X] days of the
              purchase date, we will review your request and consider issuing a
              refund. Refunds for dissatisfaction will be granted at our sole
              discretion.
            </li>
          </ul>
        </div>
        <div className="policy-subsection">
          <h4>1.2 Refund Process:</h4>
          <p>
            To request a refund, please contact our customer support team at{" "}
            <a href="mailto:info@aimhundred.com">info@aimhundred.com</a>
            with your request. We will review your request and respond within
            [X] business days.
          </p>
        </div>
        <div className="policy-subsection">
          <h4>1.3 Refund Method:</h4>
          <p>
            Refunds, when approved, will be issued using the same payment method
            used for the original purchase.
          </p>
        </div>
      </div>
      <div className="policy-section">
        <h3>2. Cancellation Policy</h3>
        <div className="policy-subsection">
          <h4>2.1 Cancellation of Subscriptions:</h4>
          <p>
            If you have subscribed to a recurring payment plan for our
            educational services (e.g., monthly or annual subscriptions), you
            may cancel your subscription at any time. Cancellation will take
            effect at the end of the current billing period.
          </p>
        </div>
        <div className="policy-subsection">
          <h4>2.2 No Partial Refunds:</h4>
          <p>
            We do not offer partial refunds for unused portions of a
            subscription term.
          </p>
        </div>
      </div>
      <div className="policy-section">
        <h3>3. Exceptions</h3>
        <div className="policy-subsection">
          <h4>
            3.1 This policy does not cover refunds or cancellations for any
            third-party products or services that may be offered or linked to
            from our website. Third-party products or services are subject to
            their own refund and cancellation policies.
          </h4>
        </div>
      </div>
      <div className="policy-section">
        <h3>4. Contact Us</h3>
        <p>
          If you have any questions or concerns regarding this Refund and
          Cancellation Policy, please contact us at{" "}
          <a href="tel:8428421300">8428421300</a>.
        </p>
      </div>
    </div>
  );
};

export default Refundandcancellation;
