import React from 'react';
import Icon1 from '../../images/svg-2.svg';
import Icon2 from '../../images/svg-5.svg';
import Icon3 from '../../images/svg-3.svg';
import { ServicesContainer,
        ServicesH1,
        ServicesWrapper,
        ServicesCard,
        ServicesIcon,
        ServicesH2,
        ServicesP 
        } from './ServicesElements';
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';


const Services = () => {
    return (
        <ServicesContainer id="services">
              <Slide top>

            <ServicesH1>AimHundred Services</ServicesH1>
              </Slide>
            <ServicesWrapper>
            <Bounce left>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Goal</ServicesH2>
                    <ServicesP>Have a Plan and Goal Skim read Content</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Exercises</ServicesH2>
                    <ServicesP>I gnore distractions Do Speed reading  exercises time tracker</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Get Curious</ServicesH2>
                    <ServicesP>Attend Daily Exam Read well AimHundred Provide Good Resource</ServicesP>
                </ServicesCard>
                </Bounce>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
