import React, {useState} from 'react';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import { Button } from '../ButtonElement';
import { animateScroll as scroll } from 'react-scroll';
import Card from './Cards/Card'
import Btn from './Button/Button';
import Fade from 'react-reveal/Fade';
import AIMLOGO from "./Cards/image/AimLogo.png"

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    const toggleBottom = () => {
        scroll.scrollToBottom();
    };

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg/>
            </HeroBg>
            <HeroContent>
            <Fade top>
                <HeroH1>
                <img src={AIMLOGO} width="350px" alt="" />
                </HeroH1>
                <HeroP>
                Being a student is easy. Learning requires actual work
                </HeroP>
                </Fade>
                <Fade left>
                <Btn />
                </Fade>
               <Card />
                <HeroBtnWrapper>
                    <Button to='signup' 
                            onMouseEnter={onHover} 
                            onMouseLeave={onHover} 
                            primary='true' 
                            dark='false' 
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact='true' 
                            offset={-80}
                            onClick={toggleBottom}>
                        Scroll To Bottom {hover ? <ArrowRight /> : <ArrowForward />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
