import React from "react";
import "./style/Teamsandcondition.css";

const Teamandcondition = () => {
  return (
    <div className="terms-container">
      <img className="img" src="/logo.png" alt="Logo"></img>
      <h2>Terms and Conditions for [AIM HUNDRED Website]</h2>
      <p>
        By using [Aim Hundred Website], you agree to comply with and be bound by
        these Terms and Conditions. Please read these terms carefully before
        using our website. If you do not agree to these terms, please do not use
        our website.
      </p>
      <h3>1. User Eligibility</h3>
      <p>
        1.1. To use [Aim hundred Educational Website], you must be at least 6
        years old, or the age of majority in your jurisdiction. If you are under
        6, you may use our website only with the consent and supervision of a
        parent or legal guardian.
      </p>
      <h3>2. User Accounts</h3>
      <p>
        2.1. You may be required to create a user account to access certain
        features of our website. You are responsible for maintaining the
        confidentiality of your account credentials and for all activities that
        occur under your account.
      </p>
      <p>
        2.2. You agree to provide accurate and complete information when
        creating your account and to update your information promptly if it
        changes. 
      </p>
      <h3>3. Use of Content</h3>
      <p>
        3.1. [Aim Hundred Website] provides educational content for personal use
        and learning purposes only. You may not reproduce, distribute, or
        otherwise use our content for commercial purposes without our express
        permission.
      </p>
      <p>
        3.2. You may not use our website to upload, share, or distribute any
        content that is illegal, defamatory, infringing on intellectual property
        rights, or harmful to others.
      </p>
      <h3>4. Privacy</h3>
      <p>
        4.1. We collect and use personal information as described in our Privacy
        Policy. By using our website, you consent to the collection and use of
        your information as outlined in the Privacy Policy.
      </p>
      <h3>5. Intellectual Property</h3>
      <p>
        5.1. All content and materials on [Aim Hundred Website], including text,
        graphics, logos, and videos, are protected by copyright and other
        intellectual property laws. You may not use, modify, or distribute our
        content without our written permission.
      </p>
      <h3>6. Termination</h3>
      <p>
        6.1. We reserve the right to terminate or suspend your access to [Aim
        Hundred Website] for any reason, including a breach of these Terms and
        Conditions.
      </p>
      <h3>7. Disclaimer of Warranties</h3>
      <p>
        7.1. [Aim Hundred Website] is provided "as is" and "as available." We
        make no warranties, express or implied, regarding the accuracy,
        completeness, or reliability of our content. 
      </p>
      <h3>8. Limitation of Liability</h3>
      <p>
        8.1. We shall not be liable for any direct, indirect, incidental,
        special, or consequential damages arising out of or in connection with
        your use of [Aim Hundred Website], even if we have been advised of the
        possibility of such damages.
      </p>
      <h3>9. Contact Us</h3>
      <p>
        If you have any questions, concerns, or requests related to these Terms
        and Conditions, please contact us at{" "}
        <a href="tel:8428421300">8428421300</a>.
      </p>
    </div>
  );
};

export default Teamandcondition;
