import React,{useState} from 'react'
import HeroSection from './index'
import Sidebar from '.././Sidebar';
import Navbar from '.././Navbar';
const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };
  return (
    <div>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection />
    </div>


  )
}

export default Header