import React from "react";
import "./style/Pricing.css";
const Pricing = () => {
  return (
    <div className="pricing-container">
      <img className="img" src="/logo.png" alt="Logo"></img>
      <h1>
        <u>Pricing</u>
      </h1>
      <div className="payment-plans">
        <h2>Payment Plans</h2>
        <p>Duration: 12 months</p>
        <p>Monthly: 500</p>
        <p className="content">Total: 6000</p>
      </div>
    </div>
  );
};

export default Pricing;
